<template>
  <fw-layout back-to="/" mobile-ready :main-sidebar="false" loading-title="UC Competitions">
    <template #main-content>
      <div v-if="!loading" class="xl:max-w-screen-lg mx-auto px-5 flex flex-col gap-5">
        <BannerLeague
          v-if="league"
          :league="league"
          :edition="edition.name"
          size="lg"
          :num-sports="stats?.total_sports ?? 0"
          :num-teams="stats?.approved_teams ?? 0"
          :num-players="stats?.total_participants ?? 0"
          :show-teams="true"
          :show-players="true"
          :show-sports="true"
          :show-edition="true"
        />
        <fw-panel v-if="league && league.description.pt && league.description.pt.length > 0" title="Sobre a liga">
          <div class="content text-sm" v-html="league.description.pt" />
        </fw-panel>
        <fw-panel :title="'Modalidades'" featured class="mt-10 mb-5">
          <fw-panel-info
            v-if="!loading && sports && sports.length == 0"
            type="basic"
            simple
            clean
            class="text-center text-gray-500"
          >
            Não existem desportos na liga atual.
          </fw-panel-info>

          <LoadingPlaceholder v-if="loading" />
          <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <CardSport v-for="sport in sports" :key="sport.key" :sport="sport" @click.native="openSport(sport.key)" />
          </div>
        </fw-panel>

        <fw-panel-info debug label="Data (raw)">
          <json-viewer :value="{ league, sports, edition }"></json-viewer>
        </fw-panel-info>

        <BannerSportUC />
      </div>
      <div v-else class="animate-pulse flex flex-col gap-5 px-5">
        <div class=" h-80 rounded-3xl bg-gray-200"></div>
        <div class="bg-gray-200 rounded-full h-8 w-48"></div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <div class="bg-gray-200 rounded-2xl h-44"></div>
          <div class="bg-gray-200 rounded-2xl h-44"></div>
          <div class="bg-gray-200 rounded-2xl h-44"></div>
          <div class="bg-gray-200 rounded-2xl h-44"></div>
          <div class="bg-gray-200 rounded-2xl h-44"></div>
          <div class="bg-gray-200 rounded-2xl h-44"></div>
        </div>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import CardSport from '@/components/cards/CardSport'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import BannerLeague from '@/components/banners/BannerLeague.vue'
import BannerSportUC from '@/components/banners/BannerSportUC'

export default {
  components: {
    CardSport,
    BannerLeague,
    BannerSportUC
  },
  data() {
    return {
      loading: true,
      league: null,
      tournaments: [],
      edition: null,
      teams: null,
      currentEditionKey: null
    }
  },

  computed: {
    stats() {
      return (
        this.league.stats ?? {
          sports: 0,
          approved_teams: 0,
          total_participants: 0
        }
      )
    },

    sports() {
      return this.league?.sports?.filter(el => el.stats?.total_tournaments > 0) ?? []
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    leagueKey() {
      return this.$route.params.key
    },

    sportKey() {
      return this.$route.params.sportKey
    },

    view() {
      return null //this.$route.meta.view
    },

    isStudent() {
      return this.league && this.league.validations && this.league.validations.is_student
    },

    isExternalUser() {
      return this.league && this.league.validations && this.league.validations.is_external
    },

    isInstitutionallUser() {
      return this.league && this.league.validations && this.league.validations.is_institutional
    },

    currentSportKey() {
      return this.$route.params.sportKey
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    currentEditionName() {
      return this.edition.name ?? 'Selecione uma edição'
    },

    filteredTournaments() {
      return this.tournaments.filter(tournament => tournament.sport === this.currentSportKey)
    }
  },

  mounted() {
    this.getLeague()
  },

  methods: {
    /*selectEdition(editionKey) {
      this.currentEditionKey = editionKey
      this.getLeague()
      this.$router.push({
        name: 'league-view',
        params: {
          key: this.leagueKey,
        },
        query: {
          edition: editionKey,
        },
      })
    },*/
    /*async loadData() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getEditions()
        console.log('getEditions :>> ', response)
        this.editions = response.data
        let currentEdition = this.editions.find(edition => edition.is_current)
        if (this.$route.query.edition) {
          currentEdition = this.editions.find(edition => edition.key === this.$route.query.edition)
        }
        this.currentEditionKey = currentEdition
          ? currentEdition.key
          : this.editions.length > 0
          ? this.editions[0].key
          : null
        this.getLeague()
      })
    },*/
    async getLeague() {
      this.loading = true
      console.log('getLeague :>> ', this.leagueKey, this.editionKey)
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getLeagueDetails(this.editionKey, this.leagueKey)
          console.log('getLeague :>> ', response)
          this.league = response.league
          this.edition = response.edition
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 750)
        }
      )
    },

    goToTournament(leagueKey, tournamentKey) {
      this.$router.push({
        name: 'tournament-view',
        params: {
          key: leagueKey,
          tournamentKey: tournamentKey,
          editionKey: this.editionKey
        }
      })
    },

    openSport(sportKey) {
      this.$router.push({
        name: 'league-view-sports',
        params: {
          key: this.leagueKey,
          sportKey: sportKey,
          editionKey: this.editionKey
        }
      })
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "callType": {
          "scholarship": "Apoio"
        },
        "notDefined": "Não definido",
        "prefix": "Prefixo",
        "createdAt": "Criado em",
        "startsAt": "Início das candidaturas",
        "endsAt": "Fim das candidaturas",
        "publishedOn": "Publicado em",
        "notFound": "Procedimento não encontrado"
      },
      "en": {
        "callType": {
          "scholarship": "Scholarship"
        },
        "notDefined": "Not defined",
        "prefix": "Prefix",
        "createdAt": "Created at",
        "startsAt": "Applications Start Date",
        "endsAt": "Applications End Date",
        "publishedOn": "Published on",
        "notFound": "call not found"
      }
    }
    </i18n>

<template>
  <div
    class="rounded-xl transition-all cursor-pointer overflow-hidden relative text-center flex-shrink-0 min-h-52 bg-white"
  >
    <div class="flex flex-col pb-4 pt-6 gap-5 text-black h-full">
      <div class="flex-1 flex flex-col justify-center gap-2 mb-2">
        <BadmintonIcon v-if="iconType == 'badminton'" class="mx-auto h-12 w-12 text-gray-600" />
        <BasketballIcon v-else-if="iconType == 'basketball'" class="mx-auto h-12 w-12 text-gray-600" />
        <SoccerIcon v-else-if="iconType == 'football'" class="mx-auto h-12 w-12 text-gray-600" />
        <FutsalIcon v-else-if="iconType == 'futsal'" class="mx-auto h-12 w-12 text-gray-600" />
        <HandballIcon v-else-if="iconType == 'handball'" class="mx-auto h-12 w-12 text-gray-600" />
        <VolleyballIcon v-else-if="iconType == 'volleyball'" class="mx-auto h-12 w-12 text-gray-600" />
        <TennisIcon v-else-if="iconType == 'tennis'" class="mx-auto h-12 w-12 text-gray-600" />
        <PingpongIcon v-else-if="iconType == 'table_tennis'" class="mx-auto h-12 w-12 text-gray-600" />
        <BoardgameIcon v-else-if="iconType == 'trivial_pursuit'" class="mx-auto h-12 w-12 text-gray-600" />
        <FwIconActivity v-else class="mx-auto h-12 w-12 text-gray-600" />

        <div v-if="sportModality.title[language]" class="font-bold text-center w-4/5 mx-auto text-lg h-12 pt-3">
          <v-clamp autoresize :max-lines="3">{{ sportModality.title[language] }}</v-clamp>
          <div v-if="sport.category[language]" class="text-base text-gray-600">{{ sport.category[language] }}</div>
        </div>

        <div v-else class="text-center mx-auto text-gray-400">Sem título</div>
      </div>

      <div class="flex justify-center gap-5">
        <div class="flex items-center">
          <ApprovedTeamsIcon class="h-12 w-12 text-gray-600" />
          <div class="text-gray-600 font-bold text-sm">{{ sport.stats.approved_teams ?? 0 }}</div>
        </div>
        <div class="flex items-center">
          <ParticipantsIcon class="h-12 w-12 text-gray-600" />
          <div class="text-gray-600 font-bold text-sm">{{ sport.stats.total_players ?? 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadmintonIcon from '@/components/icons/BadmintonIcon'
import BasketballIcon from '@/components/icons/BasketballIcon'
import SoccerIcon from '@/components/icons/SoccerIcon'
import FutsalIcon from '@/components/icons/FutsalIcon'
import HandballIcon from '@/components/icons/HandballIcon'
import VolleyballIcon from '@/components/icons/VolleyballIcon'
import TennisIcon from '@/components/icons/TennisIcon'
import PingpongIcon from '@/components/icons/PingpongIcon'
import ApprovedTeamsIcon from '@/components/icons/ApprovedTeamsIcon.vue'
import ParticipantsIcon from '@/components/icons/ParticipantsIcon.vue'
import BoardgameIcon from '@/components/icons/BoardgameIcon.vue'

export default {
  name: 'CardSport',
  components: {
    BadmintonIcon,
    BasketballIcon,
    SoccerIcon,
    FutsalIcon,
    HandballIcon,
    VolleyballIcon,
    TennisIcon,
    PingpongIcon,
    ApprovedTeamsIcon,
    ParticipantsIcon,
    BoardgameIcon
  },
  props: {
    sport: {
      type: Object,
      required: true
    }
  },
  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
    sportModality() {
      return this.sport.modality
    },
    iconType() {
      return this.sportModality.type
    },
    gender() {
      return this.sport.gender == 'U' ? 'unisex' : this.sport.gender == 'M' ? 'male' : 'female'
    }
  }
}
</script>

<i18n>
  {
    "en": {},
    "pt": {}
  }
</i18n>

<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
.progress-gradient {
  background: rgb(56, 195, 177);
  background: linear-gradient(90deg, rgba(56, 195, 177, 1) 0%, rgba(116, 246, 230, 1) 85%);
}
</style>
